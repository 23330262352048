import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types'
import { io } from 'socket.io-client'

import { Token } from 'shared/types'

export const ENVIRONMENT = EnvironmentsEnum.mainnet
export const API_TIMEOUT = 6000
export const WALLET_CONNECT_PROJECT_V2 = '57c8d914e5dd53399d8f13a92a24e545'

export const REWARDS_CONTRACT_ADDRESS = 'erd1qqqqqqqqqqqqqpgqjg4qfhs96df2z909mz7cudzav98jzm80wmfsw6y5gq'

export const BACKEND_URL = 'https://estar.games:4000'
export const MULTIVERSX_API = 'https://api.multiversx.com'
export const BACKEND_API = 'https://equistar-api-v2.estar.games'

export const EQUISTAR_IDENTIFIER = 'EQUISTAR-3f393f'
export const HORSES_IDENTIFIER = 'HORSES-d677c8'

export const socket = io(BACKEND_URL)

export const TOKEN_IDENTIFIER = 'ESTAR-461bab'

export const RECEIVE_ADDRESS =
  'erd1qqqqqqqqqqqqqpgqwppckq4pzex32gulmydakz7yw8d8er8cwmfsx54jt8'
export const GAME_ADDRESS =
  'erd1qqqqqqqqqqqqqpgqplw6qj45dvvdfcf7dcl30rp3y5zl0arawmfs6ratsj'
export const STAKING_SCS = [
  { rarity: 'Common', rewardPerNft: 0.23, scAddress: 'erd1qqqqqqqqqqqqqpgqq3uzjptflvpythrflnfxry8sf52kuedtwmfs4x6xxz' },
  { rarity: 'Rare', rewardPerNft: 0.46, scAddress: 'erd1qqqqqqqqqqqqqpgq7zz0266af4hvp76qe5zlce9tmy307x29wmfs4844sc' },
  { rarity: 'Ultra_Rare', rewardPerNft: 1.15, scAddress: 'erd1qqqqqqqqqqqqqpgq870uwhk7mdm8vn0mf452ukap8c4qdc0swmfs5nwhvv' },
  { rarity: 'Epic', rewardPerNft: 4.6, scAddress: 'erd1qqqqqqqqqqqqqpgq6f2frpql32a736qsqnkq6tu9zfk2feaawmfsjfvdzk' },
  { rarity: 'Legendary', rewardPerNft: 11.5, scAddress: 'erd1qqqqqqqqqqqqqpgqqcyldsqwrnp4w0nm20euap89h2fzgvjkwmfsq4ldyy' },
  {
    rarity: 'General', scAddress: ''
  }
]

export const tokens: Token[] = [
  {
    token: 'ESTAR',
    identifier: 'ESTAR-461bab',
    decimals: 1000000000000000000
  },
  {
    token: 'EGLD',
    identifier: 'EGLD',
    decimals: 1000000000000000000
  },
  {
    token: 'PLATA',
    identifier: 'PLATA-9ba6c3',
    decimals: 100000000
  },
  {
    token: 'WATER',
    identifier: 'WATER-9ed400',
    decimals: 1000000000000000000
  },
  {
    token: 'MEX',
    identifier: 'MEX-455c57',
    decimals: 1000000000000000000
  },
  {
    token: 'RIDE',
    identifier: 'RIDE-7d18e9',
    decimals: 1000000000000000000
  },
  {
    token: 'JCORP',
    identifier: 'JCORP-5639cb',
    decimals: 100000000
  },
  {
    token: 'XLH',
    identifier: 'XLH-8daa50',
    decimals: 1000000000000000000
  }
]
